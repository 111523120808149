<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="trigger"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <transition name="fade" mode="out-in">
        <div
          v-if="ready"
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="text-center sm:text-left w-full">
                  <h3
                    class="text-lg mb-2 leading-6 font-medium text-center w-full text-gray-900"
                    id="modal-title"
                  >
                    Bloque de reserva creada
                  </h3>
                  <div class="w-full flex justify-center item-center">
                    <div
                      class="bg-green-200 border border-green-300 text-green-700 h-20 w-20 rounded-full flex justify-center items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-10 w-10"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center"
            >
              <button
                type="button"
                @click="finish()"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </transition>
      <div
        v-if="!ready"
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="text-center sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Agendar sobrecupo
                </h3>
                <div
                  v-if="error"
                  class="w-full bg-red-200 border rounded-md border-red-300 px-4 py-2 text-red-900 mt-1 mb-2 text-sm"
                >
                  {{ error }}
                </div>
                <!-- <div class="mt-2">
                  <p class="text-sm text-gray-500 mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Porro atque, voluptas, labore optio ratione fugiat amet,
                    architecto dolores praesentium nesciunt animi et. Nesciunt
                    nisi eos similique vel veniam delectus non!
                  </p>
                </div> -->
                <div class="w-full grid grid-cols-2 gap-2">
                  <div class="col-span-2">
                    <label
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Fecha de sobrecupo
                    </label>
                    <input
                      type="date"
                      v-model="start_date"
                      required
                      class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                </div>
                <div class="w-full grid grid-cols-2 gap-2">
                  <div>
                    <label
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Hora de inicio
                    </label>
                    <input
                      type="time"
                      v-model="start_time"
                      required
                      class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Hora de término
                    </label>
                    <input
                      type="time"
                      v-model="end_time"
                      required
                      class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                </div>
                <div class="w-full">
                  <!-- <label
                    class="w-full text-left block text-sm text-left font-medium text-gray-700"
                  >
                    Nota <small class="text-gray-600 text-xs">(opcional)</small>
                  </label>
                  <label
                    class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt adipisci facilis, deleniti repellendus nisi
                    voluptatibus.
                  </label>
                  <input
                    v-model="type"
                    type="text"
                    required
                    class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    placeholder="Tipo de reserva"
                  /> -->
                  <input-checkbox
                    placeholder="Utilizar tarifa preferencial por sobre la tarifa de mi cuenta para esta reserva"
                    @checkbox="
                      value => {
                        use_rate = value;
                      }
                    "
                  />
                  <label
                    v-if="use_rate"
                    class="w-full text-left block text-sm text-left font-medium text-gray-700"
                  >
                    Tarifa preferencial para la
                    <span class="font-bold">reserva completa</span>
                  </label>
                  <!-- <label
                    v-if="use_rate"
                    class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt adipisci facilis, deleniti repellendus nisi
                    voluptatibus.
                  </label> -->
                  <input
                    v-if="use_rate"
                    v-model="rate"
                    type="number"
                    min="0"
                    required
                    class="appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    placeholder="Valor"
                  />
                  <!-- <input-checkbox
                    placeholder="Crear esta reserva automaticamente bloqueada para que ningun paciente pueda reservarla"
                    @checkbox="
                      value => {
                        blocked = value;
                      }
                    "
                  /> -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center"
          >
            <button
              type="button"
              @click="save()"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            >
              Guardar
            </button>
            <button
              type="button"
              @click="toggle()"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
            <!-- <span
              v-if="use_rate"
              class="text-sm font-semibold text-gray-700 mr-2"
              >Valor de la reserva {{ block_value }}</span
            > -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment';
import InputCheckbox from '@/components/form/InputCheckbox.vue';
import BlockService from '@/services/blockService';

function initialState() {
  return {
    start_date:
      moment().year() +
      '-' +
      (moment().month() + 1 <= 9 ? '0' : '') +
      (moment().month() + 1) +
      '-' +
      (moment().date() <= 9 ? '0' : '') +
      moment().date(),
    start_time: moment()
      .set({ hour: 0, minute: 0 })
      .format('HH:mm'),
    end_time: moment()
      .set({ hour: 0, minute: 0 })
      .format('HH:mm'),
    blocked: false,
    type: '',
    rate: null,
    use_rate: false,
    error: null,
    ready: false,
  };
}

export default {
  name: 'CreateBlockAdmin',
  components: {
    'input-checkbox': InputCheckbox,
  },
  props: {
    trigger: {
      type: Boolean,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    doctorprop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return initialState();
  },
  created() {
    moment.locale('es');
  },
  methods: {
    save() {
      if (this.ready) {
        this.toggle();
      }
      this.error = null;
      if (this.use_rate && this.rate <= 0) {
        this.error =
          'Cuando quieres agregar tarifa debes ingresar un número para la tarifa valido!';
        return;
      }
      let today = moment();
      if (this.end_date_moment.diff(this.start_date_moment) <= 0) {
        this.error =
          'La hora de inicio debe ser previa a la hora de término de la reserva';
        return;
      }
      if (this.start_date_moment.diff(today) < 0) {
        this.error = 'La hora de inicio no debe ser previa a la hora actual';
        return;
      }
      // this.toggle();
      BlockService.createAdmin(this.doctorprop.id, {
        start_date: this.start_date_moment.format('DD/MM/YYYY HH:mm'),
        finish_date: this.end_date_moment.format('DD/MM/YYYY HH:mm'),
        is_blocked: this.blocked,
        text: this.type,
        rate: null,
        full_rate: this.use_rate ? this.rate : null,
      })
        .then(() => {
          this.ready = true;
        })
        .catch(() => {
          this.error = 'Error de servidor!';
        });
    },
    finish() {
      this.$emit('toggle', 'save');
      Object.assign(this.$data, initialState());
    },
    toggle() {
      this.$emit('toggle', 'ok');
      Object.assign(this.$data, initialState());
    },
  },
  watch: {
    rate() {
      if (this.rate < 0) {
        this.rate = 0;
      }
    },
    date() {
      this.start_date = this.date;
    },
  },
  computed: {
    block_value() {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      let value = 0;
      if (this.rate) {
        let duration = moment.duration(
          this.end_date_moment.diff(this.start_date_moment)
        );
        return chileanPeso
          .format(duration.asHours() * this.rate)
          .replace(/,/g, '.');
      }
      return chileanPeso.format(value).replace(/,/g, '.');
    },
    start_date_moment() {
      return moment(this.start_date + ' ' + this.start_time, 'YYYY-MM-DD H:mm');
    },
    end_date_moment() {
      return moment(this.start_date + ' ' + this.end_time, 'YYYY-MM-DD H:mm');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="trigger"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <transition name="fade" mode="out-in">
        <div
          v-if="ready"
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="text-center sm:text-left w-full">
                  <h3
                    class="text-lg mb-2 leading-6 font-medium text-center w-full text-gray-900"
                    id="modal-title"
                  >
                    Horario creado
                  </h3>
                  <div class="mb-4 w-full flex justify-center item-center">
                    <div
                      :class="
                        errors_schedule.length != 0
                          ? 'bg-yellow-200 border border-yellow-300 text-yellow-700'
                          : 'bg-green-200 border border-green-300 text-green-700'
                      "
                      class="h-20 w-20 rounded-full flex justify-center items-center"
                    >
                      <svg
                        v-if="errors_schedule.length != 0"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-10 w-10"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-10 w-10"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <!-- <div class="mt-2">
                    <p class="text-sm text-gray-500 mb-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Porro atque, voluptas, labore optio ratione fugiat amet,
                      architecto dolores praesentium nesciunt animi et. Nesciunt
                      nisi eos similique vel veniam delectus non!
                    </p>
                  </div> -->
                  <div class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div
                        class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                      >
                        <div
                          class="border border-gray-200 overflow-hidden border-b border-gray-200 sm:rounded-lg"
                        >
                          <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  <span v-if="errors_schedule.length != 0"
                                    >({{ errors_schedule.length }}) Reservas que
                                    no pudieron crearse</span
                                  >
                                  <span v-else
                                    >Todas las reservas pudieron crearse</span
                                  >
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              class="block w-full max-h-36 overflow-y-scroll bg-white"
                            >
                              <tr
                                class="w-full"
                                v-for="(i, index) in errors_schedule"
                                :key="index"
                              >
                                <td
                                  class="px-3 w-full py-2 whitespace-nowrap text-sm text-gray-500"
                                >
                                  {{ i }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center"
            >
              <button
                type="button"
                @click="finish()"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </transition>
      <div
        v-if="!ready"
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-3xl sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="text-center sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Crear horario de trabajo
                </h3>
                <div
                  v-if="error"
                  class="w-full bg-red-200 border rounded-md border-red-300 px-4 py-2 text-red-900 mt-1 mb-2 text-sm"
                >
                  {{ error }}
                </div>
                <!-- <div class="mt-2">
                  <p class="text-sm text-gray-500 mb-2">
                    Este es el texto que aparecerá en el bloque horario creado.
                    Ejemplo: sobrecupo.
                  </p>
                </div> -->
                <div class="mt-2 mb-2 grid grid-cols-2 gap-2">
                  <div>
                    <label
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Fecha de inicio
                    </label>
                    <input
                      type="date"
                      v-model="start_date"
                      required
                      class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      class="w-full text-left block text-sm text-left font-medium text-gray-700"
                    >
                      Fecha de término
                    </label>
                    <input
                      type="date"
                      v-model="finish_date"
                      required
                      class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                </div>
                <div class="w-full grid grid-cols-7 gap-2">
                  <div
                    class="flex flex-col justify-center items-center"
                    v-for="(weekDay, weekDayIndex) in weeks"
                    :key="weekDayIndex"
                  >
                    <button
                      @click="
                        () => {
                          weeks[weekDayIndex].status = !weeks[weekDayIndex]
                            .status;
                        }
                      "
                      :class="
                        weeks[weekDayIndex].status
                          ? 'bg-green-600 text-white border-b-4 border-l border-r border-t border-green-800'
                          : 'bg-gray-100 border-b-4 border-l border-r border-t border-gray-300'
                      "
                      class="w-full mb-2 inline-flex justify-center rounded-md shadow-sm px-2 py-2 md:px-4 md:py-2 font-medium text-gray-700 focus:outline-none text-xs md:text-md"
                    >
                      <span class="hidden md:block">
                        {{ weekDayIndex }}
                      </span>
                      <span class="block md:hidden">
                        {{ weekDayIndex.substring(0, 2) }}
                      </span>
                    </button>
                    <input
                      type="time"
                      v-model="weeks[weekDayIndex].start_time"
                      required
                      class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    />
                    <input
                      type="time"
                      v-model="weeks[weekDayIndex].end_time"
                      required
                      class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                </div>
                <div class="w-full">
                  <label
                    class="w-full mt-2 text-left block text-sm text-left font-medium text-gray-700"
                  >
                    Duración de los bloques
                  </label>
                  <!-- <label
                    class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt adipisci facilis, deleniti repellendus nisi
                    voluptatibus.
                  </label> -->
                  <div class="grid grid-cols-2 gap-2">
                    <div>
                      <label
                        class="w-full mt-2 text-left block text-sm text-left font-medium text-gray-700"
                      >
                        Horas
                      </label>
                      <input
                        v-model="duration.hours"
                        type="number"
                        required
                        :min="0"
                        :max="23"
                        class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label
                        class="w-full mt-2 text-left block text-sm text-left font-medium text-gray-700"
                      >
                        Minutos
                      </label>
                      <input
                        v-model="duration.minutes"
                        type="number"
                        required
                        :min="0"
                        :max="59"
                        class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                      />
                    </div>
                  </div>
                  <!-- <label
                    class="w-full text-left block text-sm text-left font-medium text-gray-700"
                  >
                    Nota <small class="text-gray-600 text-xs">(opcional)</small>
                  </label>
                  <label
                    class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt adipisci facilis, deleniti repellendus nisi
                    voluptatibus.
                  </label>
                  <input
                    v-model="type"
                    type="text"
                    required
                    class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    placeholder="Tipo de reserva"
                  /> -->
                  <label
                    v-if="use_rate"
                    class="w-full text-left block text-sm text-left font-medium text-gray-700"
                  >
                    Tarifa preferencial para la
                    <span class="font-bold">reserva completa</span>
                  </label>
                  <!-- <label
                    v-if="use_rate"
                    class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Incidunt adipisci facilis, deleniti repellendus nisi
                    voluptatibus.
                  </label> -->
                  <input
                    v-model="rate"
                    v-if="use_rate"
                    type="number"
                    min="0"
                    required
                    class="appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    placeholder="Valor"
                  />
                  <input-checkbox
                    placeholder="Utilizar tarifa preferencial por sobre la tarifa de mi cuenta para esta reserva"
                    @checkbox="
                      value => {
                        use_rate = value;
                      }
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center"
          >
            <button
              type="button"
              @click="save()"
              :class="loading ? 'cursor-not-allowed0' : ''"
              :disabled="loading"
              class="w-full disabled:opacity-80 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            >
              <div v-if="!loading">
                Crear
              </div>
              <div
                v-if="loading"
                class="flex flex-row justify-between items-center"
              >
                <spinner-component size="15px" color="#000000" />
                <span class="ml-2">Cargando...</span>
              </div>
            </button>
            <button
              type="button"
              @click="toggle()"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
            <!-- <span
              v-if="use_rate"
              class="text-sm font-semibold text-gray-700 mr-2"
              >Valor por cada reserva {{ block_value }}</span
            > -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment';
import BlockService from '@/services/blockService';
import Spinner from '@/components/animations/SpinnerReservation.vue';
import InputCheckbox from '@/components/form/InputCheckbox.vue';

function initialState() {
  return {
    errors_schedule: [],
    weeks: {
      Lunes: {
        status: false,
        start_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
        end_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
      },
      Martes: {
        status: false,
        start_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
        end_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
      },
      Miercoles: {
        status: false,
        start_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
        end_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
      },
      Jueves: {
        status: false,
        start_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
        end_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
      },
      Viernes: {
        status: false,
        start_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
        end_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
      },
      Sabado: {
        status: false,
        start_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
        end_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
      },
      Domingo: {
        status: false,
        start_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
        end_time: moment()
          .set({ hour: 0, minute: 0 })
          .format('HH:mm'),
      },
    },
    loading: false,
    start_date:
      moment().year() +
      '-' +
      (moment().month() + 1 <= 9 ? '0' : '') +
      (moment().month() + 1) +
      '-' +
      (moment().date() <= 9 ? '0' : '') +
      moment().date(),
    finish_date:
      moment()
        .add(1, 'M')
        .year() +
      '-' +
      (moment()
        .add(1, 'M')
        .month() +
        1 <=
      9
        ? '0'
        : '') +
      (moment()
        .add(1, 'M')
        .month() +
        1) +
      '-' +
      (moment()
        .add(1, 'M')
        .date() <= 9
        ? '0'
        : '') +
      moment()
        .add(1, 'M')
        .date(),
    type: '',
    rate: null,
    use_rate: false,
    error: null,
    ready: false,
    duration: {
      hours: 1,
      minutes: 0,
    },
  };
}

export default {
  name: 'CreateScheduleAdmin',
  components: {
    'input-checkbox': InputCheckbox,
    'spinner-component': Spinner,
  },
  props: {
    trigger: {
      type: Boolean,
      required: true,
    },
    doctorprop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return initialState();
  },
  created() {
    moment.locale('es');
  },
  methods: {
    save() {
      if (this.ready) {
        this.toggle();
      }
      this.error = null;
      if (this.use_rate && this.rate <= 0) {
        this.error =
          'Cuando quieres agregar tarifa debes ingresar un número para la tarifa valido!';
        return;
      }
      if (this.end_date_moment.diff(this.start_date_moment) < 0) {
        this.error = 'La hora de inicio debe ser previa a la hora de término';
        return;
      }
      let week_days = [
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
        'Domingo',
      ];
      let total = 0;
      for (let i = 0; i < 7; i++) {
        const element = this.weeks[week_days[i]];
        if (element.status) {
          let start = moment(
            this.start_date + ' ' + element.start_time,
            'YYYY-MM-DD H:mm'
          );
          let end = moment(
            this.start_date + ' ' + element.end_time,
            'YYYY-MM-DD H:mm'
          );
          let today = moment();
          if (end.diff(start) <= 0) {
            this.error =
              'La hora de término para el ' +
              week_days[i] +
              ' no puede ser anterior a la hora de inicio';
            return;
          }
          if (start.diff(today) < 0) {
            this.error =
              'La hora de término para el ' +
              week_days[i] +
              ' no puede ser anterior a la hora actual';
            return;
          }
        } else {
          total = total + 1;
        }
      }

      if (total == 7) {
        this.error = 'Debes habilitar días para crear el horario';
        return;
      }

      if (this.duration.hours < 0 || this.duration.hours > 23) {
        this.error = 'Error en la duración de los bloques';
        return;
      }

      if (this.duration.minutes < 0 || this.duration.minutes > 59) {
        this.error = 'Error en la duración de los bloques';
        return;
      }

      if (this.duration.hours == 0 && this.duration.minutes == 0) {
        this.error = 'Los bloques no pueden no tener duración';
        return;
      }

      this.loading = true;
      let data = [];

      // we can asume at this point that every start time on a true week day will be sooner than a end time
      let counter = this.start_date_moment.clone();
      let final = this.end_date_moment.clone();
      while (final.diff(counter) >= 0) {
        let element = this.weeks[
          week_days[counter.day() - 1 == -1 ? 6 : counter.day() - 1]
        ];
        if (element.status) {
          // loop from ((start time + end time) / duration ) blocks
          let counter_hours = moment(
            counter.format('YYYY-MM-DD') + ' ' + element.start_time,
            'YYYY-MM-DD H:mm'
          );
          let final_hours = moment(
            counter.format('YYYY-MM-DD') + ' ' + element.end_time,
            'YYYY-MM-DD H:mm'
          );
          let temp = counter_hours
            .clone()
            .add(this.duration.hours, 'hours')
            .add(this.duration.minutes, 'minutes');
          while (final_hours.diff(temp) >= 0) {
            data.push({
              start_date: counter_hours.format('DD/MM/YYYY HH:mm'),
              finish_date: temp.format('DD/MM/YYYY HH:mm'),
            });
            counter_hours = temp.clone();
            temp = counter_hours
              .clone()
              .add(this.duration.hours, 'hours')
              .add(this.duration.minutes, 'minutes');
          }
        }
        counter.add(1, 'd');
      }
      BlockService.createScheduleAdmin(this.doctorprop.id, {
        data: data,
        is_blocked: false,
        text: this.type,
        rate: null,
        full_rate: this.use_rate ? this.rate : null,
      })
        .then(response => {
          this.errors_schedule = response.data;
          this.ready = true;
          this.loading = false;
        })
        .catch(() => {
          this.error = 'Error de servidor';
        });
    },
    finish() {
      this.$emit('toggle', 'save');
      Object.assign(this.$data, initialState());
    },
    toggle() {
      this.$emit('toggle', 'ok');
      Object.assign(this.$data, initialState());
    },
  },
  watch: {
    rate() {
      if (this.rate < 0) {
        this.rate = 0;
      }
    },
    'duration.hours'() {
      if (
        parseInt(this.duration.hours) > 23 ||
        parseInt(this.duration.hours) < 0 ||
        this.duration.hours == ''
      ) {
        this.duration.hours = 0;
      } else {
        this.duration.hours = parseInt(this.duration.hours);
      }
    },
    'duration.minutes'() {
      if (
        parseInt(this.duration.minutes) > 59 ||
        parseInt(this.duration.minutes < 0) ||
        this.duration.minutes == ''
      ) {
        this.duration.minutes = 0;
      } else {
        this.duration.minutes = parseInt(this.duration.minutes);
      }
    },
  },
  computed: {
    block_value() {
      let chileanPeso = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      if (this.rate != null) {
        let value =
          parseInt(this.duration.hours) + parseInt(this.duration.minutes) / 60;
        return chileanPeso.format(value * this.rate).replace(/,/g, '.');
      }
      return chileanPeso.format(0).replace(/,/g, '.');
    },
    start_date_moment() {
      return moment(this.start_date, 'YYYY-MM-DD');
    },
    end_date_moment() {
      return moment(this.finish_date, 'YYYY-MM-DD');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
